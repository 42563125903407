import React, { useMemo } from "react"
import { FormattedMessage } from "react-intl"
import Layout from "../components/Layout"
import TourBoxes from "../components/TourBoxes"

export default function Location({ pageContext, location: gatsbyLocation }) {
  const locations = useMemo(
    () =>
      typeof window !== "undefined"
        ? JSON.parse(window?.localStorage.getItem("locations")).filter(
            loc => loc.lng === pageContext.language
          )
        : [],
    []
  )
  const location = useMemo(
    () => gatsbyLocation?.state?.location || locations[0],
    [gatsbyLocation?.state?.location]
  )

  const tours = useMemo(
    () =>
      typeof window !== "undefined"
        ? JSON.parse(window?.localStorage.getItem("tours")).filter(
            t => t.location === location?.location
          )
        : [],
    [location]
  )
  return (
    <Layout pageContext={pageContext}>
      <div>
        <div
          className="page-banner"
          style={{ backgroundImage: `url(${location?.banner})` }}
        >
          <div className={`page-banner-content ${pageContext.language}`}>
            <h1>{location?.location}</h1>
            <h2>{location?.subtitle}</h2>
          </div>
        </div>
        <TourBoxes
          tours={tours}
          title={
            <FormattedMessage
              id="location_our-tours-at"
              values={{ location: location?.location }}
            />
          }
        />
      </div>
    </Layout>
  )
}

export { Head } from "../components/Head"
